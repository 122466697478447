import { HtmlText } from "../../../components/HtmlText";
import { TextPlaceholder } from "../../../components/TextPlaceholder";
import { IconTypes } from "../../../components/icons.types";
import { CatalogBuilder } from "../../../features/catalogs/catalog.builder";
import { LayoutRef, LayoutItemRef } from "../../../features/layouts/layouts";
import { renderTemplateItem } from "../templates/template.render";
import { processImage } from "../../../features/catalogs/catalog.util";
import { SortByType } from "../../../features/data/data-types";

export const layoutSortBy: SortByType[] = [
  { name: "Modified", value: "modified", o: -1 },
  { name: "Name", value: "name", o: 1 },
];

export const prepareLayoutRender = (
  builder: CatalogBuilder,
  layout: LayoutRef,
  isThumb = false
) => {
  builder.pages = [];
  builder.items = [];
  builder.addLayout(layout, false, isThumb);
};

export const renderSpecialLayout = (layout: LayoutRef) => {
  return (
    <div className="layout-body">
      <div className="layout-item special">
        <div className="thumb">{layout.content}</div>
      </div>
    </div>
  );
};

export const renderLayout = (
  builder: CatalogBuilder,
  layout: LayoutRef,
  isThumb: boolean
) => {
  if (layout.special) return renderSpecialLayout(layout);

  prepareLayoutRender(builder, layout, isThumb);

  return (
    <div className="layout-body">
      {layout.items?.map((li, i) => (
        <div key={i} className="layout-item" style={li.style}>
          {renderLayoutItem(li, isThumb)}
        </div>
      ))}
    </div>
  );
};

export const renderLayoutItem = (item: LayoutItemRef, isThumb: boolean) => {
  switch (item.di.type) {
    case "image": {
      return (
        <>
          {item.di.src && (
            <div className="image-item">
              <img
                className="content-image"
                src={item.info.src}
                alt={item.info.path}
                title={item.info.path}
                data-pos={item.di.position}
                onLoad={(e) => processImage(e.target as HTMLImageElement)}
                draggable={false}
              />
              {/* <div className="field-name">
                {item.info.title || item.di.text}
              </div> */}
            </div>
          )}
          {!item.di.src && (
            <div className="image-field">
              <div className={`field-icon ${isThumb ? "thumb" : ""}`}>
                <IconTypes.Image />
              </div>
              {!isThumb && (
                <div className="field-name">
                  <b>{item.info.title}</b> [{item.di.position}]
                </div>
              )}
            </div>
          )}
        </>
      );
    }

    case "template":
      return (
        <div className="template-field">
          {item.info.templateItems?.map((ti, i) => (
            <div key={i} className="template-item board-item" style={ti.style}>
              {renderTemplateItem(ti, false, isThumb)}
            </div>
          ))}
          {item.info.templateItems?.length === 0 && (
            <div className={`field-icon ${isThumb ? "thumb" : ""}`}>
              <IconTypes.TemplatePlaceholder />
            </div>
          )}
          {!isThumb && (
            <div className="field-name">{item.di.text || item.info.title}</div>
          )}
        </div>
      );

    default:
      return isThumb ? (
        item.di.text ? (
          <TextPlaceholder ext={item.info.extension} />
        ) : null
      ) : (
        <HtmlText content={item.di.text} scalable={item.di.scale} />
      );
  }
};
